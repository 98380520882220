.app-account-menu-inner .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0;
}
.app-account-menu-inner .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.app-account-menu-inner .app-account-menu > .ant-menu-item.ant-menu-item-only-child {
  border-left: none;
}
.app-account-menu-inner .ant-menu-inline > .ant-menu-item {
  padding: 20px !important;
  height: unset;
  border-bottom: 1px solid #eee;
  margin: 0;
}
.app-account-menu {
  font-size: 18px;
  border-left: 1px solid #eee;
  top: 0;
  height: 62vh;
  overflow: auto;
}
.app-account-menu > .ant-menu-item.ant-menu-item-only-child {
  font-size: 18px;
  border-left: 1px solid #eee;
  top: 0;
}
.account-settings-wrapper .app-footer-wrapper {
  margin-top: 0;
}
@media (min-width: 1200px) {
  main > #page-header {
    margin-bottom: 10px;
  }
}
main > #page-header .ant-menu-horizontal {
  padding-bottom: 13px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  main > #page-header .ant-menu-horizontal {
    margin-bottom: 10px;
  }
}
main > #page-header li {
  border: none;
  margin: 0;
  padding: 10px 16px;
  line-height: 1;
}
main > #page-header li.ant-menu-item:not(:last-of-type):not(.logout-btn):not(.login) {
  margin-right: 1em;
}
main > #page-header li:hover {
  border-radius: 5px;
}
main > #page-header li.ant-menu-item-active > a,
main > #page-header li.ant-menu-item-active:hover > a,
main > #page-header li.ant-menu-item-active input,
main > #page-header li.ant-menu-item-active:hover input {
  color: #fff;
  text-decoration: none;
}
main > #page-header li a,
main > #page-header li input {
  font-size: 16px;
  line-height: normal;
  padding: 0;
}
main > #page-header li.logout-btn input[value="Log Out"] {
  margin: 0;
  padding: 0;
}
