.package--barcode {
  margin: 8px 0 20px;
  padding: 0 10px;
}
.package--barcode img {
  max-width: 150px;
  height: auto;
}
.print-action {
  margin-top: 10px;
}
.print-action > a {
  font-size: 17px;
  margin-right: 20px;
}
.submissions-table table tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.submissions-table table tr th,
.submissions-table table tr td {
  padding: 5px;
  border: 1px solid #dddddd;
}
.submissions-table table tr th {
  background-color: #fff;
  border-bottom-width: 2px;
}
.submissions-table table tr td a + a {
  margin-left: 15px;
}
.submission--field {
  margin-bottom: 15px;
}
.submission--field > label {
  margin-bottom: 0;
}
.submission--field > span {
  display: block;
}
.submission--field > span p {
  font-size: 16px;
}
