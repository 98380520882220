@font-face {
  font-family: "Kaleko105";
  src: url("../../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.eot");
  src: url("../../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.woff") format("woff"), url("../../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.ttf") format("truetype"), url("../../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* The prefix to use on all css classes from ant.*/
/* An override for the html selector for theme prefixes*/
/* -------- Colors -----------*/
/* SBCA Brand */
/* Base Scaffolding Variables*/
/* ---*/
/* Background color for `<body>`*/
/* Base background color for most components*/
/* Popover background color*/
/* https://github.com/ant-design/ant-design/issues/20210*/
/* vertical paddings*/
/* containers*/
/* small containers and buttons*/
/* Form controls and items*/
/* small items*/
/* more small*/
/* vertical padding for all form controls*/
/* vertical margins*/
/* containers*/
/* small containers and buttons*/
/* Form controls and items*/
/* small items*/
/* more small*/
/* height rules*/
/* The background colors for active and hover states for things like*/
/* list items or table cells.*/
/* ICONFONT*/
/* LINK*/
/* Animation*/
/* Border color*/
/* base border outline a component*/
/* split border inside a component*/
/* width of the border for a component*/
/* style of a components border*/
/* Outline*/
/* background of header and selected item*/
/* Default grey background color*/
/* Disabled states*/
/* Shadow*/
/* Buttons*/
/* Checkbox*/
/* Descriptions*/
/* Divider*/
/* Dropdown*/
/* Empty*/
/* Radio*/
/* Radio buttons*/
/* Media queries breakpoints*/
/* Extra small screen / phone*/
/* Small screen / tablet*/
/* Medium screen / desktop*/
/* Large screen / wide desktop*/
/* Extra large screen / full hd*/
/* Extra extra large screen / large desktop*/
/* provide a maximum*/
/* Grid system*/
/* Layout*/
/* Layout light theme*/
/* z-index list, order by `z-index`*/
/* Animation*/
/* Modal*/
/* Tooltip*/
/*CollapsePanel*/
/*Dropdown*/
/* Form*/
/* ---*/
/* Input*/
/* ---*/
/* Mentions*/
/* ---*/
/* Select*/
/* ---*/
/* Normal 24px*/
/* Cascader*/
/* ---*/
/* Cascader*/
/* ----*/
/* Anchor*/
/* ---*/
/* Tooltip*/
/* ---*/
/* Tooltip max width*/
/* Tooltip text color*/
/* Tooltip background color*/
/* Tooltip arrow width*/
/* Tooltip distance with trigger*/
/* Tooltip arrow color*/
/* Popover*/
/* ---*/
/* Popover body background color*/
/* Popover text color*/
/* Popover maximum width*/
/* Popover arrow width*/
/* Popover arrow color*/
/* Popover outer arrow width*/
/* Popover outer arrow color*/
/* Popover distance with trigger*/
/* Modal*/
/* --*/
/* Progress*/
/* --*/
/* Menu*/
/* ---*/
/* dark theme*/
/* Spin*/
/* ---*/
/* Table*/
/* --*/
/* Sorter*/
/* Legacy: `table-header-sort-active-bg` is used for hover not real active*/
/* Filter*/
/* Tag*/
/* --*/
/* TimePicker*/
/* ---*/
/* Calendar*/
/* ---*/
/* Carousel*/
/* ---*/
/* Badge*/
/* ---*/
/* Rate*/
/* ---*/
/* Card*/
/* ---*/
/* Comment*/
/* ---*/
/* Tabs*/
/* ---*/
/* BackTop*/
/* ---*/
/* Avatar*/
/* ---*/
/* Switch*/
/* ---*/
/* Pagination*/
/* ---*/
/* PageHeader*/
/* ---*/
/* Breadcrumb*/
/* ---*/
/* Slider*/
/* ---*/
/* Tree*/
/* ---*/
/* Collapse*/
/* ---*/
/* Skeleton*/
/* ---*/
/* Transfer*/
/* ---*/
/* Message*/
/* ---*/
/* Motion*/
/* ---*/
/* Alert*/
/* ---*/
/* List*/
/* ---*/
/* Statistic*/
/* ---*/
/* Drawer*/
/* ---*/
/* Timeline*/
/* ---*/
/* Typography*/
/* ---*/
/* Upload*/
/* ---*/
/* Steps*/
/* ---*/
/* Notification*/
/* ---*/
/*  Result*/
/* ---*/
.app-wrapper-g .ant-layout-header {
  height: unset;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .app-wrapper-g .ant-layout-header {
    padding-inline: 30px;
    padding: 10px;
  }
}
@media screen and (max-width: 767px) {
  .navbar-header #logo img {
    max-width: 120px;
  }
}
body {
  padding-top: 104px;
}
body #page-header ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
body #page-header ul li::after {
  display: none;
}
body #page-header ul a {
  color: #bf2c37;
  background: transparent;
  outline: none;
  text-decoration: none;
  padding: 0;
}
body #page-header ul input[type=button] {
  color: #bf2c37;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}
body #page-header ul input[type=button][value="Log Out"] {
  font-size: 14.72px;
  min-height: 20px;
  line-height: normal;
  padding: 1px 10px;
  text-decoration: none;
  margin-left: 2px;
}
body #page-header ul input[type=button]:hover,
body #page-header ul input[type=button]:focus {
  text-decoration: underline;
  box-shadow: none;
}
header.navbar-default {
  border: 0;
  padding: 1em 0 0;
  height: auto;
  background-color: #222222;
  border-color: #121212;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  header.navbar-default {
    height: 90px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  header.navbar-default {
    padding-top: 5px !important;
  }
}
@media (max-width: 767px) {
  header.navbar-default {
    padding-bottom: 1em;
  }
  header.navbar-default .navbar-toggle {
    margin-top: 15px;
    border: transparent;
  }
  header.navbar-default .navbar-toggle:hover {
    background-color: #090909;
  }
  header.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
  }
}
header.navbar-default .navbar-collapse {
  border: none;
  box-shadow: none;
}
header.navbar-default .navbar-collapse .container-fluid {
  padding: 0;
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  header.navbar-default > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  header.navbar-default > .container::before,
  header.navbar-default > .container::after {
    display: none;
  }
}
header.navbar-default nav.navbar {
  margin: 0;
  border: none;
}
header.navbar-default .logo {
  padding-right: 10px;
  margin-top: -4px;
  position: relative;
  z-index: 1;
  margin-right: 5px;
  padding-left: 5px;
}
@media (max-width: 767px) {
  header.navbar-default .logo {
    padding-left: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  header.navbar-default .logo {
    margin-top: -7px;
  }
}
header.navbar-default ul.main-menu {
  background-color: transparent;
  float: right;
  border: none;
  padding: 1.5em 0 0;
}
@media (min-width: 992px) {
  header.navbar-default ul.main-menu {
    margin-top: 3px;
  }
}
header.navbar-default ul.main-menu > li {
  color: #fff !important;
  background: transparent !important;
  position: relative !important;
  border: none !important;
  cursor: pointer;
  list-style: none;
  list-style-image: none;
  padding: 0 !important;
  top: 0;
}
@media (min-width: 768px) {
  header.navbar-default ul.main-menu > li {
    height: 50px;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
header.navbar-default ul.main-menu > li:hover,
header.navbar-default ul.main-menu > li:focus,
header.navbar-default ul.main-menu > li a.active {
  color: #fff;
  text-decoration: none;
}
header.navbar-default ul.main-menu > li:hover a,
header.navbar-default ul.main-menu > li:focus a,
header.navbar-default ul.main-menu > li a.active a {
  color: #fff;
  text-decoration: none;
}
header.navbar-default ul.main-menu > li::after {
  display: none;
}
header.navbar-default ul.main-menu > li a,
header.navbar-default ul.main-menu > li .ant-menu-title-content {
  color: #fff !important;
  font-size: 18.4px;
  line-height: 21px;
  padding: 10px 15px;
  text-decoration: none !important;
}
@media (min-width: 768px) {
  header.navbar-default ul.main-menu > li a,
  header.navbar-default ul.main-menu > li .ant-menu-title-content {
    padding-top: 14.5px;
    padding-bottom: 14.5px;
  }
}
header.navbar-default ul.main-menu > li a > a,
header.navbar-default ul.main-menu > li .ant-menu-title-content > a {
  width: 100%;
  padding: 0;
  margin: 0;
}
header.navbar-default ul.main-menu .ant-menu-submenu-arrow {
  position: relative;
  margin-top: 3px;
  top: 0;
  right: 0;
  margin-right: 0;
  margin-left: 6px;
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
  vertical-align: middle;
  color: #fff !important;
  border-top: 4px solid;
  border-right: 4px dashed transparent;
  border-bottom: 4px dashed transparent;
  border-left: 4px dashed transparent;
}
header.navbar-default .mobile-menu.navbar-collapse {
  padding: 0;
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
}
@media (max-width: 767px) {
  header.navbar-default .mobile-menu.navbar-collapse {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  header.navbar-default .mobile-menu.navbar-collapse {
    height: auto !important;
    margin-top: 0;
    padding-top: 14px;
  }
}
header.navbar-default .mobile-menu.navbar-collapse ul {
  width: 100%;
  float: none;
  padding: 0;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 1024px) {
  header.navbar-default .mobile-menu.navbar-collapse ul {
    padding-top: 1.5em;
  }
}
header.navbar-default .mobile-menu.navbar-collapse ul.main-menu .ant-menu-submenu-arrow {
  margin-top: 5px;
  margin-left: 25px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  header.navbar-default .mobile-menu.navbar-collapse ul.main-menu .ant-menu-submenu-arrow {
    margin-top: 3px;
    margin-left: 22px;
  }
}
header.navbar-default .mobile-menu.navbar-collapse ul > li {
  margin-bottom: 0 !important;
}
header.navbar-default .mobile-menu.navbar-collapse ul div[role="menuitem"] {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}
header.navbar-default .mobile-menu.navbar-collapse ul div[role="menuitem"]:hover,
header.navbar-default .mobile-menu.navbar-collapse ul div[role="menuitem"]:focus {
  background-color: #000;
}
header.navbar-default .mobile-menu.navbar-collapse ul .ant-menu-submenu-arrow {
  top: 0;
}
.ant-menu-light ul.ant-menu-sub {
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 15px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  box-shadow: 0 6px 12px rgb(0 0 0%);
  background-clip: padding-box;
}
@media (max-width: 767px) {
  .ant-menu-light ul.ant-menu-sub {
    width: 100%;
    background-color: #222222 !important;
    box-shadow: none;
    margin-top: 40px !important;
    margin-left: -1px;
    padding-bottom: 5px !important;
  }
}
.ant-menu-light ul.ant-menu-sub li {
  white-space: nowrap;
  margin: 0;
  padding: 0 !important;
  height: auto !important;
  background-color: transparent !important;
}
@media (max-width: 767px) {
  .ant-menu-light ul.ant-menu-sub li {
    padding-left: 20px !important;
    margin-bottom: 0 !important;
  }
}
.ant-menu-light ul.ant-menu-sub li a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  font-size: 17.25px;
  line-height: 1.42857143;
  color: #333333;
  text-decoration: none;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .ant-menu-light ul.ant-menu-sub li a {
    color: #fff;
  }
}
.ant-menu-light ul.ant-menu-sub li a:hover,
.ant-menu-light ul.ant-menu-sub li a:focus,
.ant-menu-light ul.ant-menu-sub li a.active {
  text-decoration: none;
  color: #fff !important;
  background-color: #113743;
}
@media (min-width: 768px) {
  .ant-menu-submenu > .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18.4px;
    line-height: 21px;
    padding: 0 15px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ant-menu-submenu > .ant-menu-submenu-title {
    padding-right: 0 !important;
    margin: 2px 0 0 !important;
    height: 50px !important;
  }
}
.ant-menu-submenu-popup {
  position: fixed;
}
@media (max-width: 767px) {
  .ant-menu-submenu-popup {
    width: calc(100% + 1px);
  }
  .ant-menu-submenu-popup > ul {
    position: absolute;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ant-menu-submenu-popup {
    top: 90px !important;
    left: auto !important;
    right: 40px !important;
  }
}
.ant-menu-submenu-popup ul {
  margin-top: -1px;
}
