.app-page--baner {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .app-page--baner {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .app-page--baner.home {
    margin-top: 10px;
  }
}
.app-page--baner.home h1.page-header {
  font-weight: bold;
  color: #bf2c37;
  font-size: 1.8em;
  margin-top: 50px;
  margin-bottom: 0;
  display: block;
  clear: both;
}
.app-page--baner p {
  line-height: 1.75em;
}
.app-page--baner table th:nth-child(3) {
  text-align: center;
}
.app-page--baner .table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}
.app-page--baner .my-packages-wrapper {
  display: inline-block;
  width: 100%;
}
.docu-collection--wrapper .col-sm-3 {
  font-size: 16px;
}
.subscriber--website,
.subscriber--coid {
  display: flex;
  align-items: flex-start;
}
.subscriber--website label,
.subscriber--coid label {
  margin-bottom: 0;
}
.subscriber.container {
  padding: 0;
}
.subscriber > .col-sm-12 {
  padding-top: 10px;
}
.subscriber > .col-sm-12 > div {
  margin-bottom: 5px;
}
.subscriber .sortable-table caption {
  caption-side: top;
}
.subscriber .sortable-table th {
  position: relative;
}
.subscriber .sortable-table th span {
  display: none;
  margin-left: 0.25em;
  font-size: 20px;
  color: #28a1c7;
}
.subscriber .sortable-table th span.tooltiptext {
  background: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  color: #fff;
  white-space: nowrap;
  line-height: normal;
}
.subscriber .sortable-table th.active {
  background: #f5f5f5;
}
.subscriber .sortable-table th.active span {
  display: block;
}
.subscriber .sortable-table th.active button::before {
  width: calc(100% - 20px) !important;
}
.subscriber .sortable-table th:not(.active) button::after {
  display: none;
}
.subscriber .sortable-table th button {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: #28a1c7;
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.subscriber .sortable-table th button:hover {
  color: #1b6d87;
}
.subscriber .sortable-table th button:hover::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #1b6d87;
  bottom: 0;
  position: absolute;
}
.subscriber .sortable-table td.active {
  background: #f5f5f5;
}
.subscriber .Filter {
  display: flex;
  align-items: flex-end;
}
.subscriber .Filter > div {
  padding: 0.5em 1em 0 0;
}
.location--wrapper label {
  margin-bottom: 0;
}
.contact-form label span {
  margin-left: 4px;
}
.contact-form .form-item {
  margin-bottom: 0;
}
.contact-form-confirmation p {
  margin-bottom: 0;
}
.contact-form-confirmation a {
  color: #28a1c7;
}
