section.ant-layout.layout + .container {
  min-height: 65vh;
}
@media (max-width: 767px) {
  section.ant-layout.layout + .container {
    min-height: 50vh;
  }
}
@media (max-height: 800px) {
  section.ant-layout.layout + .container {
    min-height: 50vh;
  }
}
@media (min-width: 1100px) {
  section.ant-layout.layout + .container {
    min-height: 69vh;
  }
}
.container {
  position: relative;
}
.container .loader {
  align-items: center;
  justify-content: center;
}
.containor {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.page-header {
  font-size: 2.4em;
  color: #000;
  font-weight: 700;
  border: none;
  margin: 20px 0px;
}
.sub-header {
  font-weight: bold;
  color: #bf2c37;
  font-size: 1.8em;
}
.main-wrapper {
  margin-bottom: 80px;
}
.app-wrapper-g a:not(.btn):not([href="#"]) {
  color: #28a1c7;
}
.app-wrapper-g a:not(.btn):not([href="#"]):hover,
.app-wrapper-g a:not(.btn):not([href="#"]):focus {
  color: #1b6d87;
  text-decoration: underline;
}
.alert {
  padding: 15px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 0;
}
.alert a {
  font-weight: bold;
  color: #aaa;
}
.alert-success {
  background-color: #137f2a;
  border-color: #106914;
  color: #ffffff;
}
.alert-success a {
  color: #cccccc !important;
}
.form-control {
  display: block;
  width: 100%;
  height: 43px;
  padding: 10px 18px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #222222;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
h2,
.h2 {
  font-size: 32px;
}
h3,
.h3 {
  font-size: 26px;
}
h4,
.h4 {
  font-size: 19px;
}
h5,
.h5 {
  font-size: 15px;
}
h6,
.h6 {
  font-size: 13px;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10.5px;
  margin-bottom: 10.5px;
}
.red {
  color: red;
}
.btn:not(.btn-group-sm):not(.btn-sm) {
  font-size: 15px;
  line-height: 1.42857143;
  border-radius: 5px;
  padding: 10px 18px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 5px;
}
.btn-danger {
  background-color: #bf2c37;
  border: #bf2c37;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #96222b;
  border-color: #8d2129;
}
.table-responsive {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  border: none;
}
.table-responsive table {
  width: 100%;
  word-break: normal;
}
.PreviewTable {
  margin-top: 30px;
}
.PreviewTable th {
  font-weight: bold !important;
}
.sortable-table caption {
  caption-side: top;
}
.sortable-table th {
  position: relative;
}
.sortable-table th span {
  display: none;
  margin-left: 0.25em;
  font-size: 20px;
  color: #28a1c7;
}
.sortable-table th span.tooltiptext {
  background: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  color: #fff;
  white-space: nowrap;
  line-height: normal;
}
.sortable-table th.active {
  background: #f5f5f5;
}
.sortable-table th.active span {
  display: block;
}
.sortable-table th.active button::before {
  width: calc(100% - 20px) !important;
}
.sortable-table th:not(.active) button::after {
  display: none;
}
.sortable-table th button {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: #28a1c7;
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.sortable-table th button:hover {
  color: #1b6d87;
}
.sortable-table th button:hover::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #1b6d87;
  bottom: 0;
  position: absolute;
}
.sortable-table td.active {
  background: #f5f5f5;
}
.radio_Group {
  display: flex;
  flex-direction: row;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.radio_Group lable {
  margin-top: 15px;
  margin-left: 5px;
}
form .radio_Group label {
  margin-top: 0 !important;
}
.help-block {
  color: #999999;
  font-size: 12px;
  margin: 5px 0 10px;
  padding: 0;
}
.TestMode {
  float: left;
  padding: 10px;
}
.ApplyFilter {
  float: left;
  padding: 10px;
  padding-top: 22px;
}
.Filter {
  height: 80px;
}
.inline-text {
  display: flex;
  flex-direction: flex-start;
}
div.MuiInputBase-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 0;
}
div.MuiInputBase-root input {
  font-size: 15px;
  height: 30px !important;
  min-height: auto;
}
.txt-center {
  text-align: center !important;
}
.txt-left {
  text-align: left !important;
}
.txt-right {
  text-align: right !important;
}
.legend-title {
  display: block;
  width: 100%;
  padding: 0;
  margin-top: 0;
  margin-bottom: 21px;
  font-size: 22.5px;
  font-weight: normal;
  line-height: inherit;
  color: #222222;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.no-mrgn-top {
  margin-top: 0;
}
.no-mrgn-btm {
  margin-bottom: 0;
}
div[data-testid="three-dots-loading"] {
  display: block;
  margin: 0 auto;
  width: auto;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* Tooltip container */
.tooltip-new {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip-new .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: auto;
  height: auto;
  top: calc(100% + 10px);
  left: auto;
  padding: 4px 10px;
}
.tooltip-new:hover .tooltiptext {
  visibility: visible;
}
.tooltip-new.fa-chevron-up::before,
.tooltip-new.fa-chevron-down:before {
  display: none;
}
.tooltip-new.fa-chevron-up::after {
  content: "\f077";
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.tooltip-new.fa-chevron-down:after {
  content: "\f078";
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.ant-table-column-sorters {
  display: flex;
  justify-content: space-between;
}
.ant-table-column-has-sorters .ant-table-filter-column .ant-table-column-sorters {
  padding-right: 0;
}
