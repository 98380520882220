ul.nav-tabs {
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  display: inline-block;
  width: 100%;
  padding: 0;
}
ul.nav-tabs > li {
  float: left;
  position: relative;
  display: block;
}
ul.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 0 0 0 0;
  position: relative;
  display: block;
  padding: 10px 15px;
}
ul.nav-tabs > li.active > a {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-bottom-color: transparent;
  cursor: default;
}
