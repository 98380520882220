.main-wrapper > .container {
  min-height: calc(100vh - 300px);
  height: auto;
}
.main-wrapper img.img-responsive {
  max-width: 100%;
}
@media (max-width: 767px) {
  .main-wrapper img.img-responsive {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 767px) {
  .app-page--wrapper img {
    max-width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
ol li {
  font-size: 1.15em;
}
form .radio_Group {
  margin-bottom: 3px;
}
form .radio_Group label {
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: bold;
  cursor: pointer;
}
form .radio_Group input {
  min-height: auto;
  width: auto;
  display: inline-block;
  margin-right: 8px;
}
form .radio_Group input:hover {
  border: none;
  box-shadow: none;
}
form .form-item {
  margin-bottom: 12px;
}
form .error select,
form .error textarea,
form .error input:not([type=checkbox]):not([type=radio]) {
  border-color: #ed303b;
  box-shadow: 0 0 0 2px rgba(237, 48, 59, 0.2);
}
form .form-required {
  color: #ed303b;
}
form label {
  display: block;
}
form .bottom-border {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 21px;
  font-size: 22.5px;
  line-height: inherit;
}
.btn-danger + .btn-danger {
  margin-left: 10px;
}
.error-msg {
  color: #ed303b;
  font-weight: bold;
  display: block;
  text-align: left;
}
.pagination > li.disabled {
  display: none;
}
.pagination > li a,
.pagination > li span {
  padding: 10px 18px;
}
.pagination > li a {
  color: #28a1c7;
}
.pagination > li a[aria-label*="first"] {
  font-size: 0;
}
.pagination > li a[aria-label*="first"]::before {
  content: "« first";
  font-size: 18.4px;
}
.pagination > li a[aria-label*="previous"] {
  font-size: 0;
}
.pagination > li a[aria-label*="previous"]::before {
  content: "‹ previous";
  font-size: 18.4px;
}
.pagination > li a[aria-label*="next"] {
  font-size: 0;
}
.pagination > li a[aria-label*="next"]::before {
  content: "next ›";
  font-size: 18.4px;
}
.pagination > li a[aria-label*="last"] {
  font-size: 0;
}
.pagination > li a[aria-label*="last"]::before {
  content: "last »";
  font-size: 18.4px;
}
.pagination > li:first-child a,
.pagination > li:first-child span {
  margin-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 3;
  color: #999999;
  background-color: #f5f5f5;
  border-color: #dddddd;
  cursor: default;
}
.package-preview-form table tr:first-of-type td {
  border-top: 0;
}
button.refreshBtn {
  float: right;
}
.ant-dropdown.ant-dropdown-placement-bottomRight.slide-up-enter,
.ant-tooltip.zoom-big-fast-enter {
  display: block !important;
  opacity: 1 !important;
  -webkit-animation-timing-function: unset;
  animation-timing-function: unset;
  -webkit-animation-duration: unset;
  animation-duration: unset;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-animation-play-state: unset;
  animation-play-state: unset;
}
.ant-tooltip.zoom-big-fast-enter {
  -webkit-transform: unset;
  transform: unset;
}
