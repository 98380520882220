footer.footer {
  background-color: #000;
  color: #fff;
  padding: 45px 0 36px;
  margin-top: 3em;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  clear: both;
}
@media (max-width: 767px) {
  footer.footer {
    padding: 30px 0;
  }
}
footer.footer p {
  font-size: 18.4px;
  color: #fff;
  line-height: 1.5em;
}
@media (max-width: 767px) {
  footer.footer .text-right {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  footer.footer > .container {
    padding: 0;
  }
}
@media (max-width: 991px) {
  footer.footer > .container .col-md-6 {
    padding: 0;
  }
}
