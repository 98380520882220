.btn-md {
  font-size: 18px;
  padding: 8px 18px;
}
.btn-info {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}
.btn-info:hover {
  background-color: #575757;
  border-color: #575757;
}
.icon-pos-left svg {
  margin-right: 5px;
}
.icon-pos-right svg {
  margin-left: 5px;
}
