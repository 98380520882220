.AuthForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  padding-top: 30px;
}
.AuthForm .AuthForm__title {
  text-align: center;
}
.AuthForm img {
  width: 130px;
}
.AuthForm > * {
  width: 95%;
  max-width: 400px;
}
@media (max-width: 767px) {
  .AuthForm > * {
    width: 100%;
    max-width: 100%;
  }
}
.AuthForm label {
  text-align: left;
  color: #bf2c37;
  text-transform: uppercase;
  font-size: 1em;
}
.AuthForm input {
  display: block;
}
.AuthForm > input,
.AuthForm .ant-input-affix-wrapper {
  margin-bottom: 20px;
}
.AuthForm .MyForm__btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: 50px;
  width: 100%;
}
.AuthForm .AuthForm__btn--catch-all {
  background-color: #bf2c37;
  color: white;
}
.AuthForm .AuthForm__btn--facebook {
  background-color: #3b5a98;
  color: white;
}
.AuthForm .AuthForm__btn--google svg {
  color: #bf2c37;
}
.AuthForm .AuthForm__guest {
  color: #bf2c37;
  text-align: center;
}
.AuthForm .AuthForm__separator {
  color: #9b9b9b;
  text-align: center;
}
.AuthForm .AuthForm__separator::after {
  content: "-";
  color: inherit;
}
.AuthForm .AuthForm__separator::before {
  content: "-";
  color: inherit;
}
.AuthForm .AuthForm__footer {
  text-align: center;
}
